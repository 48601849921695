import { useDispatch, useSelector } from "react-redux";
import Logo from "../static/rbp-dark.png";
import { ROUTES } from "../router/routes";
import { clearAuth } from "../store/features/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { LogOut } from "react-feather";
import { ROLES } from "../consts/AppRoles";

export const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);

  const handleLogout = () => {
    dispatch(clearAuth());
    navigate(ROUTES.AUTH.LOGIN);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <Link
            className="navbar-brand d-flex align-items-center"
            to={ROUTES.AUTH.LOGIN}
            data-aos="zoom-in"
          >
            <img
              src={Logo}
              alt="MyntPe-logo"
              className="img-fluid"
              style={{
                height: "2rem",
              }}
            />
            <h6 className="m-0 ms-2 fw-bold text-primary">
              Royal Banc Pacific BV
            </h6>
          </Link>

          {/* show admin badge */}
          {currentUser?.role === ROLES.ADMIN && (
            <span className="badge bg-primary">Admin</span>
          )}

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {currentUser && (
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="pe-2 text-capitalize">
                      {`${currentUser?.firstName} ${currentUser?.lastName}`}
                    </span>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to={ROUTES.DASHBOARD.PROFILE.INDEX}
                      >
                        Profile
                      </Link>
                    </li>
                    <li onClick={handleLogout}>
                      <a className="dropdown-item">Logout</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export const SimpleNavbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white shadow-sm fixed-top">
        <div className="container">
          <Link
            className="navbar-brand d-flex align-items-center"
            to={ROUTES.DASHBOARD.ACCOUNTS.INDEX}
            data-aos="zoom-in"
          >
            <img
              src={Logo}
              alt="MyntPe-logo"
              className="img-fluid"
              style={{
                height: "2rem",
              }}
            />
            <h5 className="m-0 ms-2 fw-bold text-primary">MyntPe</h5>
          </Link>
        </div>
      </nav>
    </>
  );
};
