export const UNAUTHORIZED_ACCESS = "Unauthorized access";
export const REGION = "region";
export const AUTHORIZATION = "mnt-auth-token";

export const DATE_FORMAT = "dd MMM, hh:mm a";
export const DATE_FORMAT_YYYY = "yyyy-MM-dd";

// REGIONS
export const REGION_NAME_LIST = {
  AE: {
    name: "United Arab Emirates",
    code: "AE",
  },
  EG: {
    name: "Egypt",
    code: "EG",
  },
};
