import React from "react";
import BrandLogo from "../static/myntpay-logo-dark.png";

const Footer = () => {
  return (
    <>
      <footer className="py-5 text-center border-top border-light mt-5">
        <p>
          &copy; 2024 All rights reserved. Powered by
          <a
            className="ms-1 text-secondary"
            href="https://myntpe.com/"
            target="_blank"
          >
            Royal Banc Pacific BV
          </a>
        </p>
        <div className="d-flex align-items-center w-100 justify-content-center">
          <img
            className=""
            src={BrandLogo}
            style={{ height: "1rem" }}
            alt="myntpe logo"
          />
          <h6 className="m-0 ms-2 fw-bold text-primary">Myntpe</h6>
        </div>
      </footer>
    </>
  );
};

export default Footer;
